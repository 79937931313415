import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Snackbar from '@mui/material/Snackbar'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import { Close } from 'icons'
import { HOME } from '@app/constants/routes'
import { hiddenNotifications } from '@app/lib/Notifications/hiddenNotifiсations'
import { whitelist } from '@app/store/reducer'
import { whitelistExceptions } from '@app/lib/Toolbar/whitelistExceptionsWhenChangingUnitDevice'
import resetPersistent from '@app/helpers/resetPersistent'
import openCable from '@app/api/cable'
import Info from '../Info/Info'

class ConsecutiveSnackbars extends React.Component {
  constructor(props) {
    super(props)
    this.queue = []
  }

  state = {
    open: false,
    messageInfo: {},
    isWarningRemovingFromHubOpen: false,
  }

  componentDidUpdate() {
    const {
      notification,
    } = this.props
    if (notification) {
      this.updateNotification()
    }
  }

  updateNotification =() => {
    const {
      notification: {
        message, type, variant, data,
      },
      clearNotificationsChannel,
    } = this.props
    this.queue.push({
      message,
      key: new Date().getTime(),
      type,
      variant,
      data,
    })
    clearNotificationsChannel()
    this.processQueue()
  }

  renderWarningRemovingFromHub = () => {
    const { isWarningRemovingFromHubOpen } = this.state
    return (
      <Info
        open={isWarningRemovingFromHubOpen}
        onClick={this.actionAccepted}
        title="titles.warning"
        buttonName="buttons.okay"
        warningText="confirmations.removing_warning"
      />
    )
  }

  actionAccepted=() => {
    const {
      signOut,
      units,
      selectUnit,
      updateCurrentUserRole,
      patchUnit,
      selectedSerialnumber,
      syncDevices,
    } = this.props
    this.setState({ isWarningRemovingFromHubOpen: false })
    if (units.length > 1) {
      const unitIndexForSwitch = units.findIndex((unit) => unit.serial_number !== selectedSerialnumber)
      selectUnit(units[unitIndexForSwitch].serial_number)
      updateCurrentUserRole(units[unitIndexForSwitch].unit_user.role)
      patchUnit()
      whitelist.forEach((target) => {
        if (!whitelistExceptions.includes(target)) {
          store.dispatch(resetPersistent(target))
        }
      })
      const cable = openCable()
      cable.disconnect()
      cable.connect()
      return syncDevices()
    }
    return signOut()
  }

  getActionAfterNotification=(messageInfo) => {
    const {
      syncCurrentUser,
      becomeOnlineUnit,
      becomeOfflineUnit,
      fetchUnit,
      navigate,
      closeSyncErrorPopup,
      closeSyncWarningPopup,
      showActivationWarningPopup,
      showActivationErrorPopup,
      updateCurrentUser,
      closeEditUnitNamePopup,
      closeUnitList,
      selectedSerialnumber,
      syncDevices,
      closeSyncInProgressPopup,
    } = this.props
    switch (messageInfo.type) {
    case 'account_warning':
      updateCurrentUser({ unconfirmed_email: messageInfo.data.email })
      return showActivationWarningPopup()
    case 'account_blocked':
      updateCurrentUser({ unconfirmed_email: messageInfo.data.email })
      return showActivationErrorPopup()
    case 'sign_out':
      if (selectedSerialnumber === messageInfo.data.serial_number) {
        return this.setState({ isWarningRemovingFromHubOpen: true })
      }
      return syncDevices()
    case 'change_user_info':
      return syncCurrentUser()
    case 'change_user_role':
      if (selectedSerialnumber === messageInfo.data.serial_number) {
        navigate(HOME)
        closeSyncErrorPopup()
        closeSyncWarningPopup()
        closeEditUnitNamePopup()
        closeUnitList()
        return syncCurrentUser()
      }
      return null
    case 'mezzo_online':
      return becomeOnlineUnit()
    case 'mezzo_offline':
      closeSyncInProgressPopup()
      return becomeOfflineUnit()
    case 'mezzo_activated':
      if (selectedSerialnumber === messageInfo.data.serial_number) {
        return fetchUnit()
      }
      return null
    default:
      return null
    }
  }

  processQueue = () => {
    if (this.queue.length > 0) {
      const messageInfo = this.queue[this.queue.length - 1]
      if (!hiddenNotifications.includes(messageInfo.type)) {
        this.setState({
          messageInfo,
          open: true,
        })
      }
      this.getActionAfterNotification(messageInfo)
    }
  }

   handleClose = (event, reason) => {
     if (reason === 'clickaway') {
       return
     }
     this.setState({ open: false })
     this.queue.pop()
     this.processQueue()
   }

  handleClearAll = (event, reason) => {
    this.queue = []
    this.handleClose(event, reason)
  }

  render() {
    const { classes, isMobile } = this.props
    const { open, messageInfo } = this.state
    return (
      <div>
        <Snackbar
          className={classNames(!isMobile && classes.snackbar)}
          key={messageInfo.key}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          autoHideDuration={null}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span className={classes.message} id="message-id">{messageInfo.message}</span>}
          action={[
            <Button size="small" className={classes.closeAllButton} onClick={this.handleClearAll} key={messageInfo.key}>
              {I18n.t('notification_settings.clear_all')}
            </Button>,
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleClose}
              size="large"
            >
              <Close />
            </IconButton>,
          ]}
        />
        {this.renderWarningRemovingFromHub()}
      </div>
    )
  }
}

ConsecutiveSnackbars.propTypes = {
  classes: PropTypes.object.isRequired,
  notification: PropTypes.shape({
    message: PropTypes.string,
    type: PropTypes.string,
    variant: PropTypes.string,
    data: PropTypes.shape({ email: PropTypes.string }),
  }),
  clearNotificationsChannel: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
  syncCurrentUser: PropTypes.func.isRequired,
  becomeOnlineUnit: PropTypes.func.isRequired,
  becomeOfflineUnit: PropTypes.func.isRequired,
  fetchUnit: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  navigate: PropTypes.func.isRequired,
  closeSyncErrorPopup: PropTypes.func.isRequired,
  closeSyncWarningPopup: PropTypes.func.isRequired,
  showActivationWarningPopup: PropTypes.func.isRequired,
  showActivationErrorPopup: PropTypes.func.isRequired,
  updateCurrentUser: PropTypes.func.isRequired,
  closeEditUnitNamePopup: PropTypes.func.isRequired,
  closeUnitList: PropTypes.func.isRequired,
  selectedSerialnumber: PropTypes.string.isRequired,
  units: PropTypes.arrayOf(PropTypes.shape({
    serial_number: PropTypes.string,
    ssid: PropTypes.string,
    unit_user: PropTypes.shape({ role: PropTypes.string.isRequired }),
  })).isRequired,
  selectUnit: PropTypes.func.isRequired,
  updateCurrentUserRole: PropTypes.func.isRequired,
  patchUnit: PropTypes.func.isRequired,
  syncDevices: PropTypes.func.isRequired,
  closeSyncInProgressPopup: PropTypes.func.isRequired,
}

ConsecutiveSnackbars.defaultProps = {
  notification: null,
}

ConsecutiveSnackbars.displayName = 'Snackbar'

export default ConsecutiveSnackbars
