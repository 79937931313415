import React from 'react'
import { useDispatch } from '@app/store'
import { useSelector } from 'react-redux'
import { AppState } from '@app/@types/store'
import Info from '@app/components/Common/Info/Info'
import { closeActivationWarningPopup, closeActivationErrorPopup, sendAccountVerificationLink } from '@app/actions/wizard/signUp'
import { DialogContentText } from '@mui/material'
import { signOut } from '@app/actions/signOut'
import CustomWarningComponent from './CustomWarningComponent'
import useStyles from './ActivationPopups.style'

const ActivationPopups: React.FC = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { unconfirmed_email: userEmail } = useSelector((state: AppState) => state.users.currentUser.user)
  const { isActivationWarningPopupOpened, isActivationErrorPopupOpened } = useSelector((state: AppState) => state.login.signUp)

  const customTextNodeWarning = () => (
    <DialogContentText>
      {I18n.t('wizard_web.seven_days_warning')}
      <br />
      {`${I18n.t('wizard_web.please_confirm')} `}
      <span className={classes.email}>{userEmail}</span>
      .
    </DialogContentText>
  )

  const onOkButtonClick = () => {
    dispatch(closeActivationErrorPopup())
    dispatch(signOut())
  }
  const onSendButtonClick = () => {
    dispatch(closeActivationErrorPopup())
    dispatch(sendAccountVerificationLink())
    dispatch(signOut())
  }

  return (
    <>
      <Info
        open={isActivationWarningPopupOpened}
        title="titles.warning"
        warningText="wizard_web.seven_days_warning"
        buttonName="buttons.okay"
        customTextNode={customTextNodeWarning()}
        onClick={() => dispatch(closeActivationWarningPopup())}
      />
      <CustomWarningComponent
        warningText={`${I18n.t('wizard_web.blocked_warning')}\n${I18n.t('wizard_web.please_verify')} `}
        userEmail={userEmail}
        additionalWarningText={I18n.t('wizard_web.after_clicking')}
        open={isActivationErrorPopupOpened}
        rightButton="ok"
        onClose={onOkButtonClick}
        leftButton="send_link_button"
        onClick={onSendButtonClick}
      />
    </>
  )
}

export default ActivationPopups
